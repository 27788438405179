<template>
  <BaseSelect
    :id="id"
    :value="value"
    :options="options"
    label="name"
    :reduce="reduce"
    :clearable="clearable"
    :hide-borders="hideBorders"
    @selected="onSelected"
    @input="$emit('input', $event)"
  />
</template>

<script>
import NonConformitiesApi from '@/api/non-conformities-api'
import BaseSelect from '@/components/ui/select/BaseSelect.vue'

export default {
  name: 'NonConformityOriginSelect',
  components: { BaseSelect },
  props: {
    value: {
      type: [Object, String, Number],
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    reduce: {
      type: Function,
      default: item => item,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    hideBorders: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [],
    }
  },
  async mounted() {
    try {
      this.options = await NonConformitiesApi.getOrigins()
    } catch (error) {
      console.error(error)
    }
  },
  methods: {
    onSelected(selectedOption) {
      this.$emit('selection-change', selectedOption)
    },
  },
}
</script>

<style scoped>
</style>
